import firebase from "firebase/compat/app";
import 'firebase/compat/firestore'


const firebaseConfig = {
  apiKey: "AIzaSyBIVWk9V1qj8qOOFKlZlW_Wrvl9J9Tzb-k",
  authDomain: "react-mars.firebaseapp.com",
  projectId: "react-mars",
  storageBucket: "react-mars.appspot.com",
  messagingSenderId: "526945224670",
  appId: "1:526945224670:web:6e7d8ae6e9566f9be3ff4f",
  measurementId: "G-KHNEZ0BQ3W"
  };

  firebase.initializeApp(firebaseConfig);

  export default firebase